
import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

// idea to product import
import PlugAndPlay from "./components/pages/PlugAndPlay/plug-and-play";

//form import
import BookMeeting from "./components/pages/Form/bookmeeting";

//thank you
import ThankYouPage from "./components/pages/Thank/ThankYou";
import ThankDownload from "./components/pages/Thank/ThankYouDownload";

//Other links
import Ganalytics from './Ganalytics';
//Meta
import Meta from './Meta';

function App() {
  return (
    <BrowserRouter basename='/'>
    <Ganalytics />
    <Meta />
     <Routes>
        {/* Cap Digisoft Main Pages (this import will load the page on initial server run) */}
        <Route exact path="/" element={<PlugAndPlay />} />

        {/* Solutions Pages Links */}
        <Route path="/plug-and-play" element={<PlugAndPlay />} />

        {/* form route */}
        <Route path="/form" element={<BookMeeting />} />

        {/* thankyou route */}
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route path="/thankyoudownload" element={<ThankDownload />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderOne from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";
import thankYou from "../../../../src/app/assets/images/thank/thank-you.jpg";

function ThankYouPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // Accessing the state passed during navigation
  const { downloadUrl } = location.state || {};

  // console.log("downloadUrl",downloadUrl );


  // const {downloadUrl} = useParams();

  // Function to trigger download
  const downloadBook = () => {

      // navigate(`${downloadUrl}`)

    if (downloadUrl) {
      // Logic to trigger the download. This example uses a direct link.
      window.location.href = downloadUrl;
      console.log(downloadUrl);
    } else {
      console.error('Download URL not provided');
    }
  };

  return (
    <>
      <HeaderOne />
      <section className="d-flex align-items-center justify-content-center position-relative banner-sec inner mt-80 text-center text-lg-start thank-you-sec">
        <div className="container">
          <div className="home-cta mt-4 bg-light-green">
            <div className="row g-lg-5 g-4 justify-content-center">
              <div className="col-lg-6">
                <div>
                  <img src={thankYou} alt="Thank you for contacting us" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 p-4 pt-0 pt-lg-4 pb-0 pb-lg-4">
                <h1 className="fw-light">Thank you for contacting us</h1>
                <h5 className="mt-3 mt-lg-4 fw-400">We appreciate your interest in choosing our service, will look forward to work with you.</h5>
                <h5 className="mt-2 mt-lg-3 fw-400">Our representative will contact you shortly.</h5>

                <a  onClick={downloadBook} target="_blank" className="btn cust-btn-primary mt-2">
               Downlaod Book
              </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <FooterOne />
    </>
  );
}

export default ThankYouPage;



/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "../PlugAndPlay/custom.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import HeaderOne from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

// hero banner imports
import heroCenter from "../../../../src/app/assets/images/plug-and-play/hero-center.webp";
import heroCenter1 from "../../../../src/app/assets/images/plug-and-play/hero-center1.webp";
import heroCenter2 from "../../../../src/app/assets/images/plug-and-play/hero-center-2.webp";

// Partners imports
import partnerOne from "../../../../src/app/assets/images/plug-and-play/1.png";
import partnerThree from "../../../../src/app/assets/images/plug-and-play/3.png";
import partnerFour from "../../../../src/app/assets/images/plug-and-play/4.png";
import partnerFive from "../../../../src/app/assets/images/plug-and-play/5.png";
import partnerSix from "../../../../src/app/assets/images/plug-and-play/6.png";
import partnerSeven from "../../../../src/app/assets/images/plug-and-play/7.png";
import partnerEight from "../../../../src/app/assets/images/plug-and-play/8.png";
import partnerNine from "../../../../src/app/assets/images/plug-and-play/9.png";

// team imports
import teamImg from "../../../../src/app/assets/images/plug-and-play/teams.jpg";
import streamLine from "../../../../src/app/assets/images/plug-and-play/streamline.jpg";

//Customer Excellence imports
import customerIcon1 from "../../../../src/app/assets/images/plug-and-play/icon-1.png";
import customerIcon2 from "../../../../src/app/assets/images/plug-and-play/icon-2.png";
import customerIcon3 from "../../../../src/app/assets/images/plug-and-play/icon-3.png";

//Benfits icons imports
import benIcon1 from "../../../../src/app/assets/images/plug-and-play/icons/1.png";
import benIcon2 from "../../../../src/app/assets/images/plug-and-play/icons/2.png";
import benIcon3 from "../../../../src/app/assets/images/plug-and-play/icons/3.png";
import benIcon4 from "../../../../src/app/assets/images/plug-and-play/icons/4.png";
import benIcon5 from "../../../../src/app/assets/images/plug-and-play/icons/5.png";
import benIcon6 from "../../../../src/app/assets/images/plug-and-play/icons/6.png";

//best practice imports
import practiceIcon1 from "../../../../src/app/assets/images/plug-and-play/icons/listed/1.png";
import practiceIcon2 from "../../../../src/app/assets/images/plug-and-play/icons/listed/2.png";
import practiceIcon3 from "../../../../src/app/assets/images/plug-and-play/icons/listed/3.png";
import practiceIcon4 from "../../../../src/app/assets/images/plug-and-play/icons/listed/4.png";
import practiceIcon5 from "../../../../src/app/assets/images/plug-and-play/icons/listed/5.png";
import practiceIcon6 from "../../../../src/app/assets/images/plug-and-play/icons/listed/6.png";

//service icons imports
import serviceIcon1 from "../../../../src/app/assets/images/plug-and-play/serv-1.png";
import serviceIcon2 from "../../../../src/app/assets/images/plug-and-play/serv-2.png";
import serviceIcon3 from "../../../../src/app/assets/images/plug-and-play/serv-3.png";

//Testimonial imports
import testimonialVdo from "../../../../src/app/assets/images/plug-and-play/testimonial/Testimonial-Video-v2.mp4";
import testimonialPoster from "../../../../src/app/assets/images/plug-and-play/testimonial/Testmonial-Thumbnail.jpg";
import clientOne from "../../../../src/app/assets/images/plug-and-play/testimonial/c1.png";
import clientTwo from "../../../../src/app/assets/images/plug-and-play/testimonial/c2.png";
import clientThree from "../../../../src/app/assets/images/plug-and-play/testimonial/c3.png";

//Testimonial imports
import callToAction from "../../../../src/app/assets/images/plug-and-play/transform.png";

//AI gif imports
import aiGif from "../../../../src/app/assets/images/plug-and-play/ai.gif";
import tech1 from "../../../../src/app/assets/images/plug-and-play/icons/1.png";
import tech2 from "../../../../src/app/assets/images/plug-and-play/icons/8.png";

//Certificate imports
import galCollage from "../../../../src/app/assets/images/plug-and-play/gal-collage.png";
import cert1 from "../../../../src/app/assets/images/plug-and-play/certi1.png";
import cert2 from "../../../../src/app/assets/images/plug-and-play/certi2.png";
import cert3 from "../../../../src/app/assets/images/plug-and-play/certi3.png";

//import form

import Bookameeting from "../../../components/pages/Form/bookmeeting";

const testimonials = [
{
name: "Anil Keswani",
image: clientOne,
text: `CAP Digisoft Team has worked on several websites
for me. I have not found a better company to work
with. They are fast and thorough. CEO has built a
strong team.`,
},
{
name: "Cody Ngo",
image: clientTwo,
text: `"Thank you and your team for such an outstanding job
with the website and met all our requirements. We
appreciate the professionalism and quick turnaround
time!. I enjoyed working with you and your team and
will definitely keep you guys in mind for future
project.`,
},
{
name: "Terry Johnson",
image: clientThree,
text: `I want to thank you for all that you do, week in
and week out, helping us fight the battle to bring
in revenue and new clients. You work with diligence,
excellence and integrity, and I’m grateful for you.
You help us make a difference in the lives of
business owners and their families across North
America, in the lives of our team, and in my life as
well. I am most appreciative, and thankful.`,
},
];

const Products = () => {
const [isScrolled, setIsScrolled] = useState(false);

useEffect(() => {
const handleScroll = () => {
const scrollTop = window.pageYOffset;
setIsScrolled(scrollTop > 0);
};

window.addEventListener("scroll", handleScroll);

return () => {
window.removeEventListener("scroll", handleScroll);
};
}, []);

const ReadMore = ({ children }) => {
const [isReadMore, setIsReadMore] = useState(true);
const toggleReadMore = () => {
setIsReadMore(!isReadMore);
};
return (
<p className="text-light">
  {isReadMore ? `${children.slice(0, 80)}...` : children}
  <span onClick={toggleReadMore} className="read-or-hide"
    style={{ color: "#bcbcbc", cursor: "pointer", fontWeight: "500" }}>
    {isReadMore ? "Read More" : " Show Less"}
  </span>
</p>
);
};
const properties = {
prevArrow: (
<button className="cs-btn ib-btn-left">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
  </svg>
</button>
),
nextArrow: (
<button className="cs-btn btn-right ib-btn-right">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
  </svg>
</button>
),
};
return (
<>
  <div className="plug&play-page">
    <HeaderOne />

    <div class="slide_area">
      <div class="container-fluid position-relative ">
        <div class="hero-gradient-wrapper "></div>

        <div class="container custom-cn-width">
          <div class="row align-items-center position-relative hero-padding">
            <div class="col-12">
              <div class="slide_content p-0 text-center">
                <div class="typing-effect">
                  <p>
                    Plug and Play{" "}
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor"
                      class="bi bi-plugin" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1 8a7 7 0 1 1 2.898 5.673c-.167-.121-.216-.406-.002-.62l1.8-1.8a3.5 3.5 0 0 0 4.572-.328l1.414-1.415a.5.5 0 0 0 0-.707l-.707-.707 1.559-1.563a.5.5 0 1 0-.708-.706l-1.559 1.562-1.414-1.414 1.56-1.562a.5.5 0 1 0-.707-.706l-1.56 1.56-.707-.706a.5.5 0 0 0-.707 0L5.318 5.975a3.5 3.5 0 0 0-.328 4.571l-1.8 1.8c-.58.58-.62 1.6.121 2.137A8 8 0 1 0 0 8a.5.5 0 0 0 1 0" />
                    </svg>{" "}
                    Call Center Service for <br class="d-xl-block d-none" />
                    <span class="typed-text">Expert Tech Support</span>
                    <span class="cursor"></span>
                  </p>
                </div>

                <p class="text-white hero-para">
                  Comprehensive Plug and Play Call Center Service,
                  empowering clients. Efficiently manage support and
                  outreach, trust professionalism and efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="position-relative">
        <div class="padding-plug">
          <div className="plug-play-main-wrapper">
            {!isScrolled ? (
            <div class="plug-play_wrapper mx-auto position-relative text-center">
              <div class="">
                <img src={heroCenter} class="hero-center" alt="ib-img" />
              </div>
            </div>
            ) : (
            <div class="plug-margin-btm">
              <div class="hero-img-mob text-center mx-auto ">
                <img src={heroCenter1} alt="" class="img-fluid" />
              </div>
            </div>
            )}
          </div>
          <div class="mx-auto text-center px-2">
            <a class="main-btn mx-auto" href="" data-bs-toggle="modal" data-bs-target="#meeting">
              Schedule a Free Consultation
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
          <div className="container plug-play-wrapper mx-auto mt-4">
            <img src={heroCenter2} alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>

    {/* slide end */}
    <div class="container-fluid p-0" style={{ overflow: "hidden" }}>
      <div class="text-center">
        <p class="text-white mb-2">Our Trusted Clients</p>
      </div>
      <div class="Marquee">
        <div class="Marquee-content">
          <div class="Marquee-tag">
            <img src={partnerOne} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerThree} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFour} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFive} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerSix} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerSeven} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerEight} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerNine} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerOne} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerThree} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFour} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFive} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerSix} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerSeven} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerEight} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerNine} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerOne} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerThree} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFour} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFive} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerSix} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerSeven} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerEight} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerNine} class="img-fluid" alt="pp-img" />
          </div>
        </div>
      </div>
    </div>

    {/* ====== partners end ======== */}
    <div class="work_area position-relative overflow-cust cust-padding">
      <div class="container custom-cn-width">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section_wrapper cust-sec-wrapper">
              <h2 class="title wow animate__bounceInn text-white mb-4 animated" data-animation="fadeInUp"
                data-delay="1.5s" style={{ visibility: "visible" }}>
                Key <span class="grad-text">Challenges</span>
              </h2>
              <p class="text-light">
                The call center industry grapples with a high attrition rate
                of 30 to 45%, costing companies up to $20,000 per new agent
                for hiring and training. Additionally, U.S. businesses face
                difficulties in efficiently managing their customer support
                and sales teams, alongside the challenge of finding offshore
                call centers well-versed in U.S. culture and lifestyle. Our
                mission is focused on overcoming these obstacles by offering
                solutions that streamline operations and ensure cultural
                alignment, helping businesses enhance their customer
                engagement strategies.
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="stream-sec text-center">
              <img src={teamImg} class="img-fluid" alt="pp-img"></img>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* ====== key Challenges end ======== */}
    <div class="cust-padding position-relative">
      <div class="gradient-wrapper">
        <div class="aqua-grad-shape"></div>
      </div>
      <div class="container custom-cn-width">
        <div class="row align-items-center">
          <div class="col-lg-6 order-lg-1 order-2">
            <div class="stream-sec text-center">
              <img src={streamLine} alt="pp-img" class="img-fluid" />
            </div>
          </div>

          <div class="col-lg-6 order-lg-2 order-1">
            <div class="strm-cont">
              <h2 class="title wow animate__bounceInn text-white animated mb-4 animated" data-animation="fadeInUp"
                data-delay="1.5s">
                Optimized Solutions for{" "}
                <span class="grad-text">Call Center</span> Challenges
              </h2>

              <p class="text-white">
                Plug and Play Call Center offers a targeted solution to the
                common challenges within the call center sector. By
                partnering your customer service needs to our{" "}
                <span class="text-highlight">
                  proficient Central American team
                </span>
                , you effortlessly overcome the hurdles associated with
                staffing, training, and the associated financial commitments
                of infrastructure and operational activities.
              </p>
              <p class="text-white">
                Our inclusive service model comprehensively{" "}
                <span class="text-highlight">
                  addresses all dimensions of call center including project
                  coordination, hiring, training, infrastructure, service
                  management, including performance evaluation and payroll
                  administration
                </span>
                , enabling you to devote your energies to your primary
                business operations.
              </p>
              <p class="text-white">
                Our adaptable service structure facilitates the{" "}
                <span class="text-highlight">
                  efficient scaling of your customer service capabilities to
                  meet varying demand levels, ensuring both cost efficiency
                </span>{" "}
                and exceptional service quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* ---- challange end ---- */}
    <div class="service_area custom_animation mobile-res-mb position-relative cust-padding">
      <div class="gradient-wrapper">
        <div class="purple-grad-shape"></div>
      </div>

      <div class="container custom-cn-width">
        <div class="row">
          <div class="col-md-12">
            <div class="section_wrapper service_wraper mb-40">
              <h2 class="title wow animate__bounceInn text-white animated" data-animation="fadeInUp" data-delay="1.5s">
                Transformative Solutions <br />
                for <span class="grad-text">Customer Excellence</span>
              </h2>
            </div>
          </div>
        </div>
        <div class="row position-relative">
          <div class="col-md-12">
            <div class="row g-4 position-relative">
              <div class="col-lg-4">
                <div class="service_card h-100">
                  <div class="service_img service_img_one text-center">
                    <img src={customerIcon1} alt="service_img" />
                  </div>
                  <div class="service_content">
                    <h3 class="service_title text-white">
                      Superior Customer Support
                    </h3>
                    <p class="light_color mb-0">
                      Outsource your customer support to nearshore locations
                      like El Salvador for cost-efficient solutions and a
                      skilled bilingual workforce. Our tailored support
                      covers every touchpoint, allowing you to focus on core
                      operations while enhancing brand reputation with
                      dedicated support.
                    </p>

                    <div class="mt-4">
                      <h5>Tailored superior customer support solutions</h5>
                      <ul>
                        <li>
                          Elevate support with tailored solutions from
                          nearshore locations.
                        </li>
                        <li>
                          Gain a strategic advantage by outsourcing to
                          locations like El Salvador.
                        </li>
                        <li>
                          Enjoy comprehensive solutions covering every
                          customer touchpoint.
                        </li>
                        <li>
                          Partner with us for dedicated support,
                          satisfaction, and brand reputation.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="service_card h-100">
                  <div class="service_img service_img_two text-center">
                    <img src={customerIcon2} alt="service_img" />
                  </div>
                  <div class="service_content">
                    <h3 class="service_title text-white">
                      Expert Tech Support
                    </h3>
                    <p class="light_color mb-0">
                      For tech startups, quality tech support is integral to
                      the product experience. Plug and Play Call Center
                      offers specialized services tailored for startups'
                      dynamic needs, including Product Demos and Software
                      Tech Support, ensuring timely and effective
                      assistance.
                    </p>

                    <div class="mt-4">
                      <h5>
                        Our specialized tech support services for startups
                      </h5>
                      <ul>
                        <li>
                          Comprehensive guidance for product demos,
                          enhancing user understanding.
                        </li>
                        <li>
                          Tailored assistance for software startups,
                          addressing technical inquiries.
                        </li>
                        <li>
                          Flexible services adapting to startup needs and
                          customer demands.
                        </li>
                        <li>
                          Freeing startups to concentrate on core innovation
                          and growth.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="service_card h-100">
                  <div class="service_img service_img_three text-center">
                    <img src={customerIcon3} alt="service_img" />
                  </div>
                  <div class="service_content">
                    <h3 class="service_title text-white">
                      Dynamic Inside Sales Support
                    </h3>
                    <p class="light_color mb-0">
                      Dynamic inside sales support is crucial for seizing
                      opportunities and converting leads effectively.
                      Nearshore support from El Salvador offers seamless
                      integration with North American markets,
                      cost-effective scalability, and skilled professionals.
                    </p>

                    <div class="mt-4">
                      <h5>Our Inside Sales Support Solutions</h5>
                      <ul>
                        <li>
                          Targeted lead identification and nurturing for
                          high conversion potential.
                        </li>
                        <li>
                          Tailored sales support campaigns aligned with your
                          business objectives.
                        </li>
                        <li>
                          Efficient CRM-driven pipeline management for
                          seamless lead progression.
                        </li>
                        <li>
                          Integrated sales team extension dedicated to
                          achieving your objectives.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* -----customer excellence end---- */}
    <div class="cust-padding partner-sec">
      <div class="container custom-cn-width position-relative">
        <div class="st-sec-wrapp">
          <div class="sticky cust-sticky">
            <h2 class="title wow animate__bounceInn text-white animated mb-0 animated" data-animation="fadeInUp"
              data-delay="1.5s">
              Why <span class="grad-text">Partner</span> <br />
              with Us?
            </h2>

            <div class="counter-sec">
              <div class="d-lg-flex gap-lg-5 why-sec-count">
                <div class="count-margin">
                  <h3>20+</h3>
                  <h4>Years in Business</h4>
                </div>

                <div class="count-margin">
                  <h3>3000+</h3>
                  <h4>Customers</h4>
                </div>
              </div>

              <div class="d-lg-flex gap-lg-5 why-sec-count">
                <div class="count-margin">
                  <h3>400+</h3>
                  <h4>Team Members</h4>
                </div>

                <div class="count-margin">
                  <h3>5+</h3>
                  <h4>Locations</h4>
                </div>
              </div>
            </div>

            <div class="count-btn">
              <a class="main-btn" href="" data-bs-toggle="modal" data-bs-target="#meeting">
                Connect with us by scheduling a meeting
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>

          <div class="main-side-content">
            <article>
              <div class="wu-serv cust-bt-line">
                <div class="d-flex align-items-center gap-2 mb-3">
                  <div class="wu-serv-img">
                    <img src={benIcon1} alt="" />
                  </div>
                  <h4>Cost Efficiency</h4>
                </div>
                <p>
                  Achieve significant savings on staffing costs while
                  ensuring high service quality.
                </p>
              </div>

              <div class="wu-serv cust-bt-line">
                <div class="d-flex align-items-center gap-2 mb-3">
                  <div class="wu-serv-img">
                    <img src={benIcon2} alt="" />
                  </div>
                  <h4>Pricing</h4>
                </div>
                <p>
                  Our solutions are designed to be up to 50% more
                  cost-effective, delivering unmatched value without
                  compromising on service quality.
                </p>
              </div>

              <div class="wu-serv cust-bt-line">
                <div class="d-flex align-items-center gap-2 mb-3">
                  <div class="wu-serv-img">
                    <img src={benIcon3} alt="" />
                  </div>
                  <h4>Expert Management</h4>
                </div>
                <p>
                  Our dedicated team manages all staffing aspects, aligning
                  with your strategic goals.
                </p>
              </div>

              <div class="wu-serv cust-bt-line">
                <div class="d-flex align-items-center gap-2 mb-3">
                  <div class="wu-serv-img">
                    <img src={benIcon4} alt="" />
                  </div>
                  <h4>Simplified Operations</h4>
                </div>
                <p>
                  We take on payroll, reporting, and administrative tasks,
                  allowing you to focus on core activities.
                </p>
              </div>

              <div class="wu-serv cust-bt-line">
                <div class="d-flex align-items-center gap-2 mb-3">
                  <div class="wu-serv-img">
                    <img src={benIcon5} alt="" />
                  </div>
                  <h4>Flexibility and Scalability</h4>
                </div>
                <p>
                  Adjust your support team's size as needed, ensuring
                  efficiency and responsiveness.
                </p>
              </div>

              <div class="wu-serv no-pb-wu-serv">
                <div class="d-flex align-items-center gap-2 mb-3">
                  <div class="wu-serv-img">
                    <img src={benIcon6} alt="" />
                  </div>
                  <h4>Cultural Alignment</h4>
                </div>
                <p class="no-pb-wu-serv mb-0">
                  Our nearshore team is deeply familiar with US culture,
                  ensuring a seamless service experience for your customers.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    {/* -------- sticky section end -------- */}
    <div class="cust-padding-2">
      <div class="container custom-cn-width">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="title wow animate__bounceInn text-white animated mb-5 text-center animated"
              data-animation="fadeInUp" data-delay="1.5s">
              Our Top <span class="grad-text">Best Practices</span>
            </h2>
          </div>

          <div class="col-md-12">
            <div class="row g-3">
              <div class="col-lg-6">
                <div class="listed-cont">
                  <div class="d-flex align-items-center mb-3">
                    <div class="listed-img me-3">
                      <img src={practiceIcon1} alt="" class="img-fluid" />
                    </div>
                    <div>
                      <h3 class="">Employee Training and Development</h3>
                    </div>
                  </div>
                  <p>
                    Continuous training program ensure agents are updated
                    with product knowledge, communication skills, and
                    service techniques.
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="listed-cont">
                  <div class="d-flex align-items-center mb-3">
                    <div class="listed-img me-3">
                      <img src={practiceIcon2} alt="" />
                    </div>
                    <div>
                      <h3 class="">Quality Assurance</h3>
                    </div>
                  </div>
                  <p>
                    Regular monitoring maintains high service standards and
                    identifies areas for improvement.
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="listed-cont">
                  <div class="d-flex align-items-center mb-3">
                    <div class="listed-img me-3">
                      <img src={practiceIcon3} alt="" />
                    </div>
                    <div>
                      <h3 class="">Efficient Use of Technology</h3>
                    </div>
                  </div>
                  <p>
                    Leveraging advanced call center technologies such as CRM
                    systems, ACD, IVR, and workforce management tools for
                    enhanced productivity.
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="listed-cont">
                  <div class="d-flex align-items-center mb-3">
                    <div class="listed-img me-3">
                      <img src={practiceIcon4} alt="" />
                    </div>
                    <div>
                      <h3 class="">Performance Metrics and KPIs</h3>
                    </div>
                  </div>
                  <p>
                    Monitoring KPIs like AHT, FCR, CSAT scores, and NPS to
                    gauge effectiveness and identify improvement
                    opportunities.
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="listed-cont">
                  <div class="d-flex align-items-center mb-3">
                    <div class="listed-img me-3">
                      <img src={practiceIcon5} alt="" />
                    </div>
                    <div>
                      <h3 class="">Proactive Communication</h3>
                    </div>
                  </div>
                  <p>
                    Keeping customers informed about new products, services,
                    or changes and reaching out proactively to resolve
                    potential issues.
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="listed-cont">
                  <div class="d-flex align-items-center mb-3">
                    <div class="listed-img me-3">
                      <img src={practiceIcon6} alt="" />
                    </div>
                    <div>
                      <h3 class="">Agent Empowerment</h3>
                    </div>
                  </div>
                  <p>
                    Empowering agents with authority and resources to
                    resolve issues effectively, improving both Employee
                    satisfaction and customer experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* ----best practice end ---- */}
    <div class="cust-padding-2 exp-serv">
      <div class="container custom-cn-width">
        <div class="text-center text-white cust-head">
          <h2 class="title wow text-dark animate__bounceInn animated" data-animation="fadeInUp" data-delay="1.5s">
            Tailored Solutions <br />
            for <span class="grad-text">Immediate</span> Impact
          </h2>

          <p class="text-dark grad-cont-para">
            Plug and Play Call Center provides customized solutions for
            unique business challenges. Our approach ensures rapid
            integration and scalable solutions for immediate impact.{" "}
          </p>

          <h4 class="text-dark">Here’s how we make this happen:</h4>
        </div>

        <div class="row align-items-center">
          <div class="col-md-12 ">
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-6">
                <div class="process_card wow animate__fadeIn px-3">
                  <div class="serv-icons sec-primary-ic">
                    <img src={serviceIcon1} alt="" />
                  </div>

                  <div class="process_content">
                    <h4 class="text-dark">Customized Expertise</h4>
                    <h6 class="sub-head-grad">A Foundation for Success:</h6>

                    <ul class="grad-list">
                      <li>
                        <b>Exclusive Customer Success Team:</b> We assign a
                        dedicated team to each client, fostering a deep
                        partnership.
                      </li>
                      <li>
                        <b>Industry-Specific Knowledge:</b> Our teams
                        specialize in various industries, offering tailored
                        insights and solutions.
                      </li>
                      <li>
                        <b>Personalized Strategy Development:</b> We
                        collaborate closely to align strategies with your
                        brand's voice.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6">
                <div class="process_card wow animate__fadeIn px-3" data-animation="fadeInUp" data-delay="1.5s"
                  data-wow-duration="1.2s">
                  <div class="serv-icons sec-primary-ic">
                    <img src={serviceIcon2} alt="" />
                  </div>
                  <div class="process_content">
                    <h4 class="text-dark">Quick Integration</h4>
                    <h6 class="sub-head-grad">
                      Seamless Transition for Swift Results:
                    </h6>

                    <ul class="grad-list">
                      <li>
                        <b>Streamlined Onboarding Process:</b> Quick and
                        efficient onboarding for rapid implementation.
                      </li>
                      <li>
                        <b>Advanced Technological Infrastructure:</b>{" "}
                        Cutting-edge tech integration for seamless service.
                      </li>
                      <li>
                        <b>Immediate Operational Uplift:</b> Day one sees a
                        boost in operational capabilities.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6">
                <div class="process_card no-bt wow animate__fadeIn px-3" data-animation="fadeInUp" data-delay="2s"
                  data-wow-duration="1.5s">
                  <div class="serv-icons sec-primary-ic">
                    <img src={serviceIcon3} alt="" />
                  </div>
                  <div class="process_content">
                    <h4 class="text-dark">Adaptive Scaling</h4>
                    <h6 class="sub-head-grad">
                      Growing with Your Business:
                    </h6>

                    <ul class="grad-list">
                      <li>
                        <b>Flexible Service Offerings:</b> Easily scalable
                        solutions to meet evolving business needs.
                      </li>
                      <li>
                        <b>Resource Allocation Optimization:</b> Continuous
                        monitoring ensures efficient resource utilization.
                      </li>
                      <li>
                        <b>Long-term Partnership for Growth:</b> Committed
                        to adapting services for sustained success and
                        growth.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* -------immediate impacts end -------- */}
    <div class="clients_area cust-padding bg-black">
      <div class="container custom-cn-width">
        <div class="row">
          <div class="col-md-12">
            <div class="section_wrapper text-center mb-lg-5 mb-4">
              <h2 class="title wow animate__fadeIn text-white" data-animation="fadeInUp" data-delay="2s"
                data-wow-duration="2.5s">
                Our Client <span class="grad-text">Testimonials</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-5">
          <div class="col-xl-9">
            <div class="testivdo-cont text-center mb-4">
              <h4 class="mb-3">
                Raving Reviews: Our Client's Success Story
              </h4>
              <p>
                Unveil the inspiring transformation of one of our valued
                clients in their own words. Dive into their captivating
                testimonial to learn how our services played a pivotal role
                in their journey to success. Get ready to be motivated and
                see how we can make a difference for you too. Don't miss
                this extraordinary client success story.
              </p>
            </div>

            <video class="testi-vdo" poster={testimonialPoster} controls="autoplay">
              <source src={testimonialVdo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-9">
            <Slide className="brand-slider plug-slider px-lg-5" easing="ease" {...properties} slidesToScroll={1}
              slidesToShow={2} autoplay={true} arrows={false} indicators={true} responsive={responsiveSettings}>
              {testimonials.map((testimonial, index) => (
              <div className="each-slide mx-3" key={index}>
                <div className="card testi-card">
                  <div className="testimonial_wrapper wow animate__fadeIn">
                    <div class="testtimonial_content">
                      <div class="testimonial_star">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>

                      <ReadMore>{testimonial.text}</ReadMore>
                    </div>

                    <div class="rows">
                      <div class="client_info client_info-one client_info_three d-flex cust-testi-info">
                        <div class="client_img">
                          <img src={testimonial.image} alt="author_info" />
                        </div>
                        <div class="client_meta">
                          <h4>{testimonial.name}</h4>
                          {/*
                          <!-- <p>ABC CEO</p> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>
    </div>

    {/* -----testimonial end ------- */}
    <div class="cust-padding">
      <div class="container custom-cn-width">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <h2 class="title wow animate__bounceInn text-white animated mb-4 animated" data-animation="fadeInUp"
              data-delay="1.5s">
              We warmly{" "}
              <span class="grad-text">
                invite <br class="d-md-block d-none" />
                you
              </span>{" "}
              to visit us!
            </h2>

            <p class="text-white">
              Located in El Salvador, our call center operates in close
              synergy with our head office in Dallas. We're eager to host
              you in El Salvador, offering you a firsthand glimpse into our
              vibrant work culture and providing an opportunity to engage
              directly with our exceptional team of agents. It's an
              excellent chance for you to experience the dedication and
              expertise that define our services and witness how we can
              transform your customer support operations firsthand.
            </p>

            <div class="mt-5">
              <a class="main-btn" href="" data-bs-toggle="modal" data-bs-target="#meeting">
                {" "}
                Schedule a consultation today!
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="text-center transform-img">
              <img src={callToAction} alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* ----------CTA1 end-------- */}
    <div class="cust-padding">
      <div class="container custom-cn-width">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center strategy-sec">
              <h2 class="title wow animate__bounceInn text-white animated mb-4 animated" data-animation="fadeInUp"
                data-delay="1.5s">
                We embrace technology <br class="cust-br" />
                <span class="grad-text">swiftly and efficiently</span>
              </h2>

              <p class="text-white">
                Our call center services and our agents are at the forefront
                of technological advancements. Leveraging AI, we automate
                follow-ups, ensuring no opportunity is missed. With Machine
                Learning, we gain insights into prospect behavior,
                optimizing our follow-up schedule for maximum engagement.
                Our use of Auto Dialers enables us to contact prospects
                simultaneously, enhancing our outreach efficiency.
                Recognizing that adaptation and innovation are key to
                success, we are dedicated to integrating these proven
                technologies into our Plug and Play Call Center solutions,
                ensuring our clients benefit from the most cutting-edge
                tools and methods available.
              </p>

              <h4 class="text-white">Our Commitment to Excellence</h4>
            </div>
          </div>

          <div class="col-xl-8">
            <div class="d-flex ai-card mt-4">
              <div class="card-grad-1">
                <h4 class="mb-2">AI-Driven Efficiency</h4>
                <p>
                  Our agents are equipped with AI tools for superior
                  service.
                </p>

                <div class="text-center mt-5">
                  <img src={aiGif} alt="" />
                </div>
              </div>

              <div>
                <div class="card-grad-2 mb-4">
                  <div class="d-flex">
                    <div class="flex-shrink-0 pe-4">
                      <img src={tech1} alt="" style={{ width: "40px" }} />
                    </div>
                    <div>
                      <h4 class="mb-2">Personalized Services</h4>
                      <p class="mb-0">
                        Tailored solutions that align with your business
                        goals.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card-grad-2">
                  <div class="d-flex">
                    <div class="flex-shrink-0 pe-4">
                      <img src={tech2} alt="" style={{ width: "40px" }} />
                    </div>
                    <div>
                      <h4>Operational Excellence</h4>
                      <p class="mb-0">
                        We set and monitor high productivity standards to
                        ensure unparalleled efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/*---------- tech end --------- */}
    <div class="cust-padding">
      <div class="container position-relative custom-cn-width">
        <div class="gradient-aqua"></div>
        <div class="glass-box position-relative">
          <div class="row align-items-center">
            <div class="col-xxl-6">
              <div class="cert-cont">
                <h2 class="title wow animate__bounceInn text-white mb-4 animated" data-animation="fadeInUp"
                  data-delay="1.5s">
                  Certified Excellence
                </h2>
                <p class="text-white">
                  Our programs are bolstered by CAP Digisoft's ISO 27001 and
                  HIPAA certifications, emphasizing our commitment to
                  quality and data security in bringing your app to life.
                  Through a combination of expert guidance, strategic
                  development, and comprehensive market analysis, we aim to
                  elevate your app into the successful 0.5%, ensuring it is
                  not only developed with precision but also positioned for
                  market success and growth.
                </p>
              </div>

              <div class="mt-5">
                <div class="row g-3">
                  <div class="col-lg-4">
                    <div class="card cust-hero-card dark-grad">
                      <div class="card-body">
                        <div class="mb-4">
                          <img src={cert3} class="cert-img" alt="" />
                        </div>
                        <p class="mb-0 text-white">
                          HIPAA
                          <br />
                          Compliance
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="card cust-hero-card dark-grad">
                      <div class="card-body ">
                        <div class="d-flex flex-wrap mb-4 align-items-center gap-2">
                          <div>
                            <img src={cert1} class="cert-img" alt="" />
                          </div>
                          <p class="mb-0 ps-1 certfi-num">9001:2015</p>
                        </div>

                        <p class="mb-0 text-white">
                          Business Process
                          <br />
                          (Process Facilities)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card cust-hero-card dark-grad">
                      <div class="card-body ">
                        <div class="d-flex flex-wrap mb-4 align-items-center gap-2">
                          <div>
                            <img src={cert2} class="cert-img" alt="" />
                          </div>
                          <p class="mb-0 ps-1 certfi-num">27001:2013</p>
                        </div>

                        <p class="mb-0 text-white">
                          Data Center
                          <br />
                          (IBM and AWS)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xxl-6">
              <div class="text-center mt-xxl-0 mt-5">
                <img src={galCollage} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* ------------- certificate end --------- */}
    <div class="cust-padding-2">
      <div class="container custom-cn-width">
        <div class="row">
          <div class="col-12">
            <div class="end-cta-sec text-center">
              <h2 class="title wow animate__bounceInn text-white mb-4 animated" data-animation="fadeInUp"
                data-delay="1.5s">
                <span class="grad-text">Complimentary,</span> No-Obligation
                Trial
              </h2>

              <p>
                We offer qualified prospects an opportunity to experience
                our solutions firsthand, absolutely free of charge and
                without any obligations. This trial is our way of
                demonstrating the tangible benefits our services can bring
                to your business. Don't hesitate—schedule a meeting with us
                today, and let's embark on a journey toward your company's
                growth together.
              </p>

              <div class="mt-5">
                <a href="" class="main-btn mx-auto" data-bs-toggle="modal" data-bs-target="#meeting">
                  Book your meeting now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterOne />
  </div>

  {/* Modal Section Start Here */}
  <div className="modal fade" id="meeting" tabindex="-1" aria-labelledby="apply-modal" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Book a meeting</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <Bookameeting />
        </div>
      </div>
    </div>
  </div>
  {/* Section End Here */}
</>
);
};

const responsiveSettings = [
{
breakpoint: 991,
settings: {
slidesToShow: 4,
slidesToScroll: 1,
},
},
{
breakpoint: 575,
settings: {
slidesToShow: 2,
slidesToScroll: 1,
},
},
{
breakpoint: 320,
settings: {
slidesToShow: 1,
slidesToScroll: 1,
},
},
];

export default Products;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoLight from "../../app/assets/images/logo-light.png";
import BookMeeting from "../../components/pages/Form/bookmeeting";
import $ from "jquery";

const HeaderOne = () => {

  return (
    <>
      <div class="header_area">
          <div class="container main-cont custom-cn-width">
            <div class="row align-items-center">
              <div class="col-sm-12">
                <div class="d-sm-flex align-items-center">
                  <div class="logo text-center">
                    <Link to="https://capdigisoft.com/">
                    <img
                      id="logo_header"
                      class="logo-main"
                      src={LogoLight}
                      alt="ib-img"
                    />
                    </ Link>
                    
                  </div>

                  <div class="main_menu d-sm-block d-none ms-auto">
                    <nav>
                      <a
                        class="main-btn"
                        href=""
                        data-bs-toggle="modal"
                        data-bs-target="#meeting"
                      >
                        Book a Meeting
                        <i class="fas fa-chevron-right"></i>
                      </a>
                    </nav>
                  </div>
                </div>

                <div class="sidebar_icon d-none">
                  <a href="javascript:void();" class="open_burger"></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div
        className="modal fade"
        id="meeting"
        tabindex="-1"
        aria-labelledby="apply-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Book a meeting</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <BookMeeting />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderOne;

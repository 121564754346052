/* eslint-disable react/jsx-no-target-blank */
import React,{ useState, useEffect } from 'react';
import { useLocation , Link} from 'react-router-dom';
import LogoLight from "../../app/assets/images/logo-light.png";

const FooterOne = () => {
  const { pathname } = useLocation();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

return (
<>

  <div class="copyright_area bg-black">
    <div class="container">
      <div class="row align-items-center justify-content-between" style={{ padding: "20px 0 15px" }}>
        <div class="col-xl-3 col-lg-12 text-xl-start text-center">
          <div class="logo mb-xl-0 mb-3">
            <a href="https://capdigisoft.com/">
              <img src={LogoLight} alt="site_logo" />
            </a>
          </div>
        </div>

        <div class="col-xl-6 col-md-9 text-xl-center text-md-start text-center">
          <div class="copyright_text mb-md-0 mb-3">
            <p class="text-white text-xl-center text-md-start text-center mb-0">
              © 2004 - 2024{" "}
              <a href="https://capdigisoft.com/" style={{color:"#52bdc7"}} class="fw-medium cap-link">
                CAP Digisoft Solution
              </a>
              , Inc. All Rights Reserved.
            </p>
          </div>
        </div>

        <div class="col-xl-3 col-md-3 text-md-end text-center">
          <div class="social-links">
            <div class="d-flex social-icon-custm">
              <a href="https://www.facebook.com/capdigisoftsolutions/" target="_blank" class="pe-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-facebook" viewBox="0 0 16 16">
                  <path
                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951">
                  </path>
                </svg>
              </a>
              <a href="https://in.linkedin.com/company/cap-digisoft-solutions-inc." target="_blank" class="pe-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-linkedin" viewBox="0 0 16 16">
                  <path
                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z">
                  </path>
                </svg>
              </a>
              <a href="https://twitter.com/CAPDigisoft" class="pe-3" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-twitter-x" viewBox="0 0 16 16">
                  <path
                    d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z">
                  </path>
                </svg>
              </a>
              <a href="https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw" class="" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube"
                  viewBox="0 0 16 16">
                  <path
                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z">
                  </path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {showButton && (
    <div className="scroll-up">
        <Link to="javascript:void(0)" onClick={scrollToTop} className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></Link>
    </div>
    )}
</>
);
};

export default FooterOne;